.about-author-content .author-bio {
    max-width: 800px;
}
.about-author-content .author-links {
    display: flex;
    --bs-gutter-y: 0.5rem;
    margin-top: 0.5rem;
}
.about-author-content .author-links a {
    color: var(--bs-text-black);
}