.top-banner {
    text-align: center;
    font-size: x-large;
    padding: 0 0 2.5rem;
}
.category-title:not(:empty) {
    margin-bottom: 1.5rem;
}
h1.booklist-title { 
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
}
.breadcrumbs {
    font-size: small;
}
.breadcrumbs a, .breadcrumbs a:hover, .breadcrumbs a:visited {
    color: var(--bs-text-black);
}
.loader {
    height: 1px;
    width: 100%;
    --c:no-repeat linear-gradient(#7d26eb 0 0);
    background: var(--c),var(--c),#d7b8fc;
    background-size: 60% 100%;
    animation: l16 3s infinite;
}
@keyframes l16 {
    0%   {background-position:-150% 0,-150% 0}
    66%  {background-position: 250% 0,-150% 0}
    100% {background-position: 250% 0, 250% 0}
}
.pagination {
    margin-bottom: 2rem;
}
.pagination .page-item .page-link {
    border: none;
    color: var(--bs-text-black);
    background-color: white;
}
.pagination .page-item.active .page-link {
    border-bottom: 2px solid var(--violet);
    color: var(--violet);
    font-weight: 500;
}
.pagination .page-item.disabled .page-link {
    color: var(--bs-gray-500);
}
