.go-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    color: var(--violet);
    border: 1px solid var(--violet);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    font-size: 32px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s, color 0.3s, background-color 0.3s;
    z-index: 1000;
  }
  .go-to-top-button.visible {
    opacity: 0.8;
    visibility: visible;
  }
  .go-to-top-button:hover {
    color: white;
    background-color: var(--violet);
  }
  .go-to-top-button i {
    display: block;
    position: absolute;
    top: -7px;
    left: 1px;
  }