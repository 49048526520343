.book-page .retailers {
    width: 128px;
}
.book-page .retailers.retailers-wide {
    width: 300px;
    
}
.book-page .retailers a { 
    display: inline-block;
    margin: 0 0.25rem 0.25rem 0;
    /*color: var(--bs-text-black);
    border: 1px solid var(--bs-secondary);
    text-decoration: none;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 14px;
    white-space: nowrap;*/
}
.book-page .retailers.retailers-wide a { 
    margin-right: 1rem;
}
.book-page .retailers a img {
    max-width: 120px; 
}
.book-page .retailers .amazon {
    width: 70px;
}
.book-page .retailers .bn {
    width: 120px;
}
.book-page .retailers.retailers-wide .bn {
    margin-top: -8px;
}
.book-page .retailers .bam {
    width: 70px;
}
.book-page .retailers .powells {
    width: 60px;
}
.book-page .retailers .bookshop {
    width: 120px;
}
.book-page .retailers .bn {
    width: 120px;
}
.book-page .retailers .bn {
    width: 120px;
}
