.books {
  --bs-gutter-y: 1.5rem;
}
.books .card {
  flex-direction: row;
    min-height: 183px;
    overflow-y: hidden;
    border-width: 0;
}
.books.favorites:not(.loading) .card:not(.favorite) {
  opacity: 0.3;
}
.books > .col:not(:has(*)) {
  display: none;
}
.books .card.attention {
  border-right: 3px solid var(--bs-danger);
}
.books .card-body {
  min-width: 128px;
  color: var(--bs-body-color);
  display: flex;
  flex-direction: column;
}
.books .card .date {
  margin: 0 auto 0.5rem 0;
  font-size: small;
  color: var(--bs-secondary-color);
}
.books .card .fav-toggle {
  color: var(--bs-gray-500);
  cursor: pointer;
}
.books .card .authors ul {
  padding-left: 0;
  list-style-type: none;
}
.books .card .authors li {
  white-space: nowrap;
  overflow-x: hidden;
}
.book-link {
  color: var(--bs-text-black);
  text-decoration: none;
}
.books .card .book-link img {
  border-radius: 6px;
}
.books .card .badge.ebook {
  color: cornflowerblue;
  border: 1px solid cornflowerblue;
  background-color: white !important;
  align-self: flex-start;
  margin-top: 10px;
  padding: 0 6px 3px;
  font-weight: 400;
}
.books .card .dev-menu button {
  border: 0;
  padding: 0;
  background-color: var(--bs-card-color);
  width: 6px;
  margin-top: 6px;
}
.books .card .img-checked {
  line-height: 33px;
  position: absolute;
  right: 12px;
  font-size: 2rem;
}
.books .card .dev-menu button::after {
  display: none;
}
.books .card .dev-menu button i {
  color: var(--bs-secondary-color);
}