.top-nav-bookmark {
    display: inline-block;
    font-size: 18px;
    margin-right: -3px;
    color: var(--violet);
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--violet);
}
.dropdown-item > i.bi {
    margin-right: 0.5rem;
}
.search-form input {
    border-radius: 18px;
}
.user-image {
    width: 36px;
    height: 36px;
    border: 1px solid var(--violet);
    cursor: pointer;
    overflow: hidden;
    max-width: 36px;
    min-width: 36px;
    max-height: 36px;
    min-height: 36px;
}
.letter-avatar {
    width: 36px;
    height: 36px;
    border: 1px solid var(--violet);
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    color: var(--violet);
    padding-top: 4px;
    font-weight: 500;
    text-transform: uppercase;
}
#userBtn button { text-decoration: none; }
#userBtn .dropdown-toggle::after { display: none; }