:root {
  --bs-body-color: var(--bs-gray-900);
  --bs-black: var(--bs-body-color);
  --bs-text-black: var(--bs-bs-black);
  --violet: #7d26eb;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tooltip {
  --bs-tooltip-bg: var(--bs-gray-800);
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.125rem;
}

.infinite-scroll-component {
  height: unset !important;
  overflow: unset !important;
}
.alert {
  /*display: inline-block;*/
  background-color: white;
  color: var(--bs-black);
  border-width: 0 3px;
  text-align: center;
}
.alert-success {
  border-color: var(--bs-green);
}
.alert-danger {
  border-color: var(--bs-red);
}
.alert-primary {
  border-color: var(--violet);
}