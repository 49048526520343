.book-page .book-image {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.68);
    width: 128px;
}
.book-page .book-image-md {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.68);
    width: 300px;
}
.book-page .book-header {
    border-bottom: 1px solid var(--violet);
    position: relative;
}
.book-page .book-header .fav-toggle {
    color: var(--bs-gray-500);
    cursor: pointer;
  }
  
.book-page .book-header i.bi-square-fill {
    position: absolute;
    right: 0;
    bottom: 0;
    color: var(--violet);
}
.book-page .book-header h1 { line-height: 1;}
.book-page .book-info { max-width: 800px; }
.book-page .book-description, .book-page .about-authors-content { 
    font-size: 14px; 
    overflow-y: hidden;
    border-bottom: 1px solid var(--bs-gray-400);
    border-left: 3px solid var(--violet);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 8px;
}
.book-page .description-toggle, .book-page .about-authors-toggle {
    color: var(--bs-gray-500);
    background-color: white;
    border: 1px solid var(--bs-gray-400);
    border-top-width: 0;
    border-radius: 0 0 4px 4px;
    font-size: 14px;
    padding: 0 4px 4px;
    line-height: 0.8;
    float: right;
    margin-top: -1px;
}
.book-page .flip-vertical {
    transform: scaleY(-1);
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
}
.book-page .author-link, .book-page .ctg-link {
    color: var(--bs-text-black);
}
.book-page .ctg-line i.bi-chevron-right {
    font-size: 10px;
    position: relative;
    top: -1px;
}
.book-page .isbn {
    display: inline-block;
    font-size: small;
    border: 1px solid var(--bs-black);
    border-radius: 8px;
    margin: 0 0.5rem;
    padding: 0 4px;
}