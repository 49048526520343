#root { min-height: 100vh; }
body > footer {
    color: var(--bs-gray-400);
    font-size: 14px;
}
body > footer a, body > footer a:visited, body > footer a:active, body > footer a:hover {
    color: var(--bs-gray-100);
} 
b, strong, .fw-bold {
    font-weight: 500 !important;
}
.text-violet { 
    color: var(--violet) !important; 
}
.bg-violet { 
    background-color: var(--violet) !important; 
}
.btn-primary {
    --bs-btn-color: #fff; /* Keep white for text to ensure good contrast */
    --bs-btn-bg: #7d26eb; /* Base background color: vibrant purple */
    --bs-btn-border-color: #7d26eb; /* Border color matches the base */
    
    /* Hover state */
    --bs-btn-hover-color: #fff; /* Maintain white for text */
    --bs-btn-hover-bg: #6b20c9; /* Slightly darker purple for hover effect */
    --bs-btn-hover-border-color: #621bb5; /* Darker shade for hover border */

    /* Focus state */
    --bs-btn-focus-shadow-rgb: 125, 38, 235; /* RGB for focus shadow, matches base background */

    /* Active state */
    --bs-btn-active-color: #fff; /* Keep white for text */
    --bs-btn-active-bg: #621bb5; /* Even darker purple for active state */
    --bs-btn-active-border-color: #5a17a7; /* Darker, complementary active border color */
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow for depth */

    /* Disabled state */
    --bs-btn-disabled-color: #f0e6ff; /* Light purple for a muted effect */
    --bs-btn-disabled-bg: #7d26eb; /* Keep the base color but could adjust for less vibrancy */
    --bs-btn-disabled-border-color: #7d26eb; /* Match the background for consistency */

    font-weight: 500;
}
