.login-modal .modal-body {
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
}
.login-modal .or {
    position: absolute;
    top: -13px;
    left: calc(50% - 30px);
    padding: 0 20px;
    background-color: white;
    color: var(--bs-gray-600);
}